<template>
    <v-container class="view-ordenestrabajo" grid-list-md>

        


        <v-layout row wrap>
            <v-flex xs12 v-if="ordenes"  >
        
                <!-- <v-layout justify-end>
                    <v-flex xs12>
                        <div right class="search-wrapper">
                            <input type="text" v-model="search" v-bind:placeholder="$t('ordenestrabajo_busqueda_titulo')"/>
                            <label>{{$t('ordenestrabajo_busqueda_titulo')}}</label>
                        </div>
                    </v-flex>
                </v-layout> -->
                <v-card-title style="padding: 0px 0px 0px 0px;">
                    <v-spacer class="titulo-combi-search">ORDENES DE TRABAJO ({{filteredList.length}})</v-spacer>
                        
                    <v-text-field style="max-width:500px;width: 100%;"
                    v-model="search"
                    append-icon="search"
                    label="Buscar..."
                    single-line
                    hide-details
                    ></v-text-field>
                </v-card-title>

                <v-switch
                    v-model="fullorders"
                    label="Ver todas las ordenes"
                    color="primary"
                    style="padding: 0px 0px 10px 0px;"
                    hide-details
                    @change="data()"
                ></v-switch>


                <v-card class="xs-12 mb-3 cardlist" v-for="(item, index) in filteredList" :key="index" >
                        
                            <v-card-text>
                                <router-link  :to="`/ordentrabajo/${item.codorden}`">
                                <v-chip label color="#c72118" text-color="white" class="ml-0 mr-3 mb-3">
                                    <v-icon left>label</v-icon>{{item.codorden}}
                                </v-chip>
                                
                                <v-chip class="mb-3" v-if="item.fechainiprevista" label >
                                    <v-icon left>av_timer</v-icon>{{ formatDate(item.fechainiprevista) }} <span v-if="item.fechafinprevista">&nbsp;&nbsp;&nbsp;->&nbsp;&nbsp;&nbsp;</span> {{ formatDate(item.fechafinprevista) }}
                                </v-chip>

                                <h3>{{item.tipo}}</h3>
                                <!-- <h3>{{item.prioridad}} - {{item.tipo}}</h3> -->
                                </router-link>
                                <div class="text">
                                    <div class="mt-2"><b>CLIENTE: </b>{{item.nombrecliente}}</div>
                                    <div v-if="item.codcliente!=item.codclientefinal"><b>CLIENTE FINAL: </b>{{item.nombreclientefinal}}</div>
                                        <router-link  :to="`/ordentrabajo/${item.codorden}`">
                                            <p class="desccorta" text-color="white">{{item.descripcioncorta}}</p>
                                        </router-link>

                                        <div v-if="item.descripcionlarga && item.descripcionlarga.length > 100" class="desccorta" stylw='font-size:8px;'
                                        @click="opendialog(item)" >
                                            {{item.descripcionlarga.substring(0, 99)}} ... <i style="color:#c72118;"> Leer mas </i> 
                                        </div>
                                        <div v-if="item.descripcionlarga && item.descripcionlarga.length <= 100" class="desclarga" stylw='font-size:8px;' text-color="white"> {{item.descripcionlarga}} </div>
                                </div>
                            </v-card-text>
                    </v-card> 

                <v-dialog v-model="dialogdelete.active" max-width="490">
                    <v-card>
                    <v-card-title style='background-color: #c72118; color:white;'>{{ dialogdelete.item.codorden }}</v-card-title>
                    <v-card-text>{{dialogdelete.item.descripcionlarga}}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click.native="closedialog()">Cerrar</v-btn>          
                    </v-card-actions>
                    </v-card>
                </v-dialog>

                </v-flex>
                <v-flex  xs12 v-else > 
                    <v-test >
                        NO HAY ORDENES QUE MOSTRAR
                    </v-test>

                    <v-switch
                        v-model="fullorders"
                        label="Ver todas las ordenes"
                        color="primary"
                        style="padding: 0px 0px 10px 0px;"
                        hide-details
                        @change="data()"
                    ></v-switch>

                </v-flex>
            </v-layout>
 
            <!-- <v-card-title>
                  
                    <v-flex aling-start>
                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            v-bind:label="$t('clientes_busqueda_titulo')"
                            single-line
                        ></v-text-field>
                    </v-flex>
                </v-card-title>


            <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="ordenes"
                    item-key="name"
                    class="elevation-1"
                >
                <template slot="items" slot-scope="props">
                    <tr>
                        <td>aa</td>
                    </tr>
                </template>
                <template v-slot:no-data>
                    <v-alert :value="true" color="error" icon="warning">
                        {{$t('ordenestrabajo_vacio')}}
                    </v-alert>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                        {{$t('ordenestrabajo_busqueda')}} "{{ search }}" {{$t('ordenestrabajo_busqueda_vacio')}}
                    </v-alert>
                </template> 
            </v-data-table> -->


    </v-container>
</template>

<script>
import { gw } from '@/gw.js'

export default {
    mixins: [gw],
    name: 'OrdenesTrabajo',
    data: (vm) => ({
        ordenes:[],
        fullorders: false,
        //ordenes_search:[],
        search: '',
        dialogdelete: {
            active: false,
            item:{}
        },
        headers: [{
                    text: 'Lista ordenes',
                    align: 'left',
                    value: 'search'
                }]
    }), 
    mounted() {
        this.data();
        this.setBreadcrumbs([
            {
              text: 'MRP',
              disabled: true,
              href: 'ordenestrabajo',
            }
          ])
    },
    methods: {
        data(){
            let _this = this;
            let post ={'coduser': this.auth.coduser,'fullorders': this.fullorders};
            this.api("/peticion/ordenestrabajo", post).then(_return => {
                if(_return['success']=="1"){
                    _this.ordenes = _return['datos'];
                    if (_this.ordenes !== undefined) {
                        let num=0;
                        _this.ordenes.forEach(function(element) {
                            _this.ordenes[num]['search'] = Object.values(element).toString().toLowerCase();
                            num++;
                        });
                    }
                }
                else if(_return["success"] == "2"){_this.toasted(resp.msg,"error","error");}
                else{
                    _this.ordenes = [];
                }
            });
        },
        opendialog(item){
            this.dialogdelete.active = true; 
            this.dialogdelete.item = item;
            document.getElementsByTagName("body").style = "overflow:hidden;";
        },
        closedialog(){
            this.dialogdelete.active = false;
              document.getElementsByTagName("body").style = "overflow:auto;";
        }
    },
    computed: {
        filteredList() {
            if (this.ordenes) {
                return this.ordenes.filter(o => {
                    if(this.ordenes.length < 100 || this.search.length>2){
                        if(o['search'].search(this.search.toLowerCase()) != -1){
                            this.closekeyboard();
                            return this.ordenes;
                        }
                    }
                    else{
                        this.closekeyboard();
                        return this.ordenes;
                    }
                })
            } else{
                return null;
            }
             
        }
    }
}
</script>